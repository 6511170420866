<template>
    <div class="regist_thirdparty_select">
        <div class="title_wrap">
            <div class="ltitle">채널 연동 사전신청</div>
            <div class="description">
                채널 연동 사전 신청 시 소프트웨어 설치 후 별도의 설정 없이<br>채널 연동이 완료된 상태로 애프터닥을 이용하실 수 있습니다.
            </div>
        </div>
        <div class="thirdparty_list sns">
            <button @click="action_KakaoConsult">
                <div class="ic_virtual kakaoconsult">
                    <img src="@/assets/img/regist_thirdparty/ic_regist_kakao.png" alt="">
                </div>
                <div class="info">
                    <div class="channel_name">카카오 상담톡</div>
                    <div class="channer_desc">카카오 상담톡으로 문의한 고객을 애프터닥에서 응대할 수 있어요.</div>
                </div>
            </button>
            <button @click="action_KakaoFriend">
                <div class="ic_virtual kakaofriend">
                    <img src="@/assets/img/regist_thirdparty/ic_regist_kakao.png" alt="">
                </div>
                <div class="info">
                    <div class="channel_name">카카오 알림톡</div>
                    <div class="channer_desc">카카오 알림톡을 활용해 관리 자동화 메시지 발송, 마케팅에 활용할 수 있어요.</div>
                </div>
            </button>
            <button disabled="disabled">
                <img src="@/assets/img/regist_thirdparty/ic_regist_instagram.png" alt="">
                <div class="info">
                    <div class="channel_name">인스타그램 <span class="waiting_label">출시예정</span></div>
                    <div class="channer_desc">인스타그램으로 문의한 고객을 애프터닥에서 응대할 수 있어요.</div>
                </div>
            </button>
            <button disabled="disabled">
                <img src="@/assets/img/regist_thirdparty/ic_regist_line.png" alt="">
                <div class="info">
                    <div class="channel_name">LINE <span class="waiting_label">출시예정</span></div>
                    <div class="channer_desc">라인으로 문의한 고객을 애프터닥에서 응대할 수 있어요.</div>
                </div>
            </button>
        </div>
    </div>
</template>


<script>
export default {
    methods: {
        action_KakaoConsult() {
            this.$router.push({name: 'RegistKakaoConsult'})
        },
        action_KakaoFriend() {
            this.$router.push({name: 'RegistKakaoFriend'})
        }
    }
}
</script>