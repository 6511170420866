<template>
    <div v-if="isShow" class="loading_bg">
        <span class="loader"></span>
    </div>
</template>


<script>
export default {
    props: {
        isShow: Boolean
    }
}
</script>


<style scoped>

/* loading */
.loading_bg {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  display: block;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  animation: rotate 1s linear infinite;
}
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid var(--color_primary_1);
  animation: spinner 2s linear infinite;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.complete_bg {
  width: 100%;
  height: 100%;
  background-color: var(--color_fe);
  z-index: 100;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: up 0.2s 1 ease-out;
}
@keyframes up {
  0% {
    top: 100%;
  }
  100% {
    top: 0;
  }
}
</style>