<template>
    <div class="dm_wrap">
        <div class="dm_header">
            <div class="w_1080">
                <img src="../../assets/img/demo_intro/logo.svg" alt="" class="dm_logo">
            </div>
        </div>
        <div class="dm_intro">
            <div class="w_1080">
                <div class="s_title">all-in-one</div>
                <div class="l_title">고객관리를 위한 모든 것,<br>애프터닥에서 한 번에</div>
                <div class="dm_btn_wrap">
                    <a ref="floatingButton" class="btn_dm_contact" @click="action_Contact">
                        상담신청
                        <img src="../../assets/img/demo_intro/dm_arrow_right.svg" alt="">
                    </a>
                </div>
            </div>
        </div>
        <div class="dm_intro2">
            <div class="w_1080">
                <div class="s_title">체험하기</div>
                <div class="m_title">상담 - 마케팅까지<br>고객관리를 완벽하게</div>
                <div class="dm_contents">
                    <div class="dm_cont" @click="action_SelectVideo(option01)">
                        <div class="cont_title">{{ option01.additionalTitle }}</div>
                        <div class="cont_desc">고객의 치료사이클에 따라 사후관리 상담, 재내원 안내 등 고객관리를 자동화 할 수 있습니다.</div>
                        <div class="cont_action_guide">자세히 보기</div>
                        <div class="cont_img">
                            <img src="../../assets/img/demo_intro/dm_cont01.png" alt="">
                        </div>
                    </div>
                    <div class="dm_cont" @click="action_SelectVideo(option02)">
                        <div class="cont_title">{{ option02.additionalTitle }}</div>
                        <div class="cont_desc">고객들의 치료니즈에 따라 응답률이 높은 마케팅을 쉽고 빠르게 시작할 수 있습니다.</div>
                        <div class="cont_action_guide">자세히 보기</div>
                        <div class="cont_img">
                            <img src="../../assets/img/demo_intro/dm_cont02.png" alt="" >
                        </div>
                    </div>
                    <div class="dm_cont" @click="action_SelectVideo(option03)">
                        <div class="cont_title">{{ option03.additionalTitle }}</div>
                        <div class="cont_desc">문의가 들어오는 채널을 애프터닥으로 통합하여 고객을 놓치지 않고 관리해 보세요!</div>
                        <div class="cont_action_guide">자세히 보기</div>
                        <div class="cont_img">
                            <img src="../../assets/img/demo_intro/dm_cont05.png" alt="" >
                        </div>
                    </div>
                </div>
                <div class="dm_contents">
                    <div class="dm_cont" @click="action_SelectVideo(option04)">
                        <div class="cont_title">{{ option04.additionalTitle }}</div>
                        <div class="cont_desc">맞춤 매뉴얼을 기반으로 누가 응대해도 상관없이 상담퀄리티를 유지할 수 있습니다.</div>
                        <div class="cont_action_guide">자세히 보기</div>
                        <div class="cont_img">
                            <img src="../../assets/img/demo_intro/dm_cont04.png" alt="" >
                        </div>
                    </div>
                    <div class="dm_cont" @click="action_SelectVideo(option05)">
                        <div class="cont_title">{{ option05.additionalTitle }}</div>
                        <div class="cont_desc">예약기능을 통해 고객 상담부터 예약까지 끊김이 없는 업무 프로세스를 만들어 보세요.</div>
                        <div class="cont_action_guide">자세히 보기</div>
                        <div class="cont_img">
                            <img src="../../assets/img/demo_intro/dm_cont03.png" alt="" >
                        </div>
                    </div>
                    <div class="dm_cont" @click="action_SelectVideo(option06)">
                        <div class="cont_title">
                            {{ option06.additionalTitle }}
                            <img src="../../assets/img/demo_intro/dm_badge_beta.svg" alt="">
                        </div>
                        <div class="cont_desc">외국인 상담도 OK! 채팅 중 간편하게 스위칭하여 실시간 번역이 가능합니다.</div>
                        <div class="cont_action_guide">자세히 보기</div>
                        <div class="cont_img">
                            <img src="../../assets/img/demo_intro/dm_cont06.png" alt="" >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="dm_footer">
            <div class="dm_footer_wrap">
                <b>㈜메디팔</b>
                <ul>
                    <li>대표 : 강종일</li>
                    <li>사업자등록번호 : 494-88-01653</li>
                </ul>
                <ul>
                    <li>대표번호 : 070 · 5180 · 4070</li>
                    <li class="line_none">이메일 : contact@medipal.co.kr</li>
                </ul>
                <ul>
                    <li> 주소 : 서울특별시 강남구 테헤란로 420, 16층 (KT 선릉타워 West) </li>
                </ul>
                <b class="copyright">© 2024 Medipal Inc. All rights reserved.</b>
            </div>
        </footer>
        <!-- 플로팅 버튼 -->
        <div class="btn_dm_float_wrap">
            <div class="btn_dm_float fade-in-up" v-if="isShowFloatingButton" @click="action_Contact">
                <div class="txt">🎉 지금 바로 신청해 보세요!</div>
                <div class="sub">
                    
                    <img src="../../assets/img/demo_intro/dm_arrow_right.svg" alt="">
                </div>
            </div>
        </div>
        <!-- 비디오 플레이어 -->
        <VideoLayer
            :isShow="isShowPlayer"
            :option="selectedOptions"
            @close="action_DeselectVideo"
        />
        <!-- 신청 팝업 -->
        <!-- 하다희 : 재한님이 제안준 Contact 이용? -->
         <div class="dm_pop_overlay" v-show="false">
            <div class="dm_pop_wrap white">
                <div class="dm_pop_title_wrap">
                    <span class="dm_pop_title">상담신청</span>
                    <img src="../../assets/img/demo_intro/dm_close.svg" alt="">
                </div>                
                <div class="dm_pop_scroll_wrap">
                    <div class="dm_pop_desc">
                        <b>아래 양식에 맞춰 문의를 남겨주시면 담당자가 확인 후 빠르게 연락드리겠습니다.</b><br> 제출하신 상담 신청서는 절대 외부로 공유되지 않으며, 오직 도입 상담을 위한 준비과정에서만 사용됩니다.
                    </div>
                    <div class="dm_form_group">
                        <div class="dm_form_title">성함<span class="red">*</span></div>
                        <div class="dm_form_input w_full">
                            <input type="text" class="dm_input" placeholder="성함을 입력해 주세요.">
                        </div>
                    </div>
                    <div class="dm_form_group">
                        <div class="dm_form_title">연락처<span class="red">*</span></div>
                        <div class="dm_form_input w_full">
                            <input type="text" class="dm_input" placeholder="연락받으실 전화번호를 입력해 주세요.">
                        </div>
                    </div>
                    <div class="dm_form_group">
                        <div class="dm_form_title">병·의원명<span class="red">*</span></div>
                        <div class="dm_form_input w_full">
                            <input type="text" class="dm_input" placeholder="예) 애프터닥의원">
                            <label class="dm_checkwrap">
                                <input type="checkbox">
                                <span class="checkmark"></span>
                                개원 예정
                            </label>
                        </div>
                    </div>
                    <div class="dm_form_group">
                        <div class="dm_form_title">지역<span class="red">*</span></div>
                        <div class="dm_form_input">
                            <input type="text" class="dm_input" placeholder="예) 서울"> <span class="mgr_15 mgl_5"> 시</span>
                            <input type="text" class="dm_input" placeholder="예) 강남"> <span class="mgr_15 mgl_5"> 구</span>
                        </div>
                    </div>
                    <div class="dm_form_group">
                        <div class="dm_form_title">관심있으신 항목을 체크해 주세요.<span class="red">*</span></div>
                        <div class="dm_form_grid">
                            <label class="dm_checkwrap">
                                <input type="checkbox">
                                <span class="checkmark"></span>
                                병·의원의 매출 성장
                            </label>
                            
                            <label class="dm_checkwrap">
                                <input type="checkbox">
                                <span class="checkmark"></span>
                                타 의원과의 차별화 포인트
                            </label>
                            
                            <label class="dm_checkwrap">
                                <input type="checkbox">
                                <span class="checkmark"></span>
                                환자의 만족도에 대한 관심
                            </label>
                            
                            <label class="dm_checkwrap">
                                <input type="checkbox">
                                <span class="checkmark"></span>
                                새로운 서비스 도입에 대한 관심
                            </label>
                            
                            <label class="dm_checkwrap">
                                <input type="checkbox">
                                <span class="checkmark"></span>
                                병·의원 확장
                            </label>
                            
                            <label class="dm_checkwrap">
                                <input type="checkbox">
                                <span class="checkmark"></span>
                                기타
                            </label>
                            
                        </div>
                    </div>
                </div>
                <div class="dm_btn_wrap">
                    <a class="btn_dm_contact">
                        신청하기
                        <img src="../../assets/img/demo_intro/dm_arrow_right.svg" alt="">
                    </a>
                </div>
            </div>
         </div>
    </div>
</template>

<script>
import VideoLayer from './VideoLayer.vue'
export default {
    components: { VideoLayer },
    data() {
        return {
            selectedOptions: undefined,

            floatingButtonObserver: undefined,
            isShowFloatingButton: false
        }
    },
    computed: {
        isShowPlayer: function() {
            return this.selectedOptions != undefined
        },
        defaultVideoOptions: function() {
            return {
                autoplay: false,
                controls: false,
                sources: [{
                    src: '',
                    type: 'video/mp4'
                }]
            }
        },
        option01: function() {
            let options = JSON.parse(JSON.stringify(this.defaultVideoOptions))
            options.sources[0].src = 'https://afterdoc-video.s3.ap-northeast-2.amazonaws.com/homepage/intro/%EC%83%81%EB%8B%B4%EC%9E%90%EB%8F%99%ED%99%94.mp4'
            options.additionalTitle = '관리 자동화'
            options.additionalDesc = '오늘 내원한 환자분들의 치료태그만 입력하면, 치료후 안내사항, 일자별 케어 메시지, 재시술주기에 맞춘 재내원 안내까지 자동으로 진행됩니다.'
            return options
        },
        option02: function() {
            let options = JSON.parse(JSON.stringify(this.defaultVideoOptions))
            options.sources[0].src = 'https://afterdoc-video.s3.ap-northeast-2.amazonaws.com/homepage/intro/%EC%9E%90%EB%8F%99%ED%99%94_%EB%A7%88%EC%BC%80%ED%8C%85.mp4'
            options.additionalTitle = '재진 마케팅'
            options.additionalDesc = '병원 방문 가능여부가 확실한 재진환자를 대상으로 쉽고 빠른 셀프 마케팅을 진행해 보세요. 고객 니즈별로 세분화된 마케팅 메시지를 전송하여 고객의 응답률을 높일 수 있습니다.'
            return options
        },
        option03: function() {
            let options = JSON.parse(JSON.stringify(this.defaultVideoOptions))
            options.sources[0].src = 'https://afterdoc-video.s3.ap-northeast-2.amazonaws.com/homepage/intro/%EC%B1%84%EB%84%90%EC%9D%BC%EC%9B%90%ED%99%94.mp4'
            options.additionalTitle = '고객채널 일원화'
            options.additionalDesc = '여러 상담 채널을 사용할수록 관리가 중요합니다. 고객과 소통하는 다양한 채널들을 애프터닥에서 통합 관리해 보세요. 고객을 놓치지 않고 빠른 응대가 가능합니다.'
            return options
        },
        option04: function() {
            let options = JSON.parse(JSON.stringify(this.defaultVideoOptions))
            options.sources[0].src = 'https://afterdoc-video.s3.ap-northeast-2.amazonaws.com/homepage/intro/%EB%B0%B1%EA%B3%BC%EC%82%AC%EC%A0%84.mp4'
            options.additionalTitle = '시/수술 백과사전'
            options.additionalDesc = '우리병원 맞춤 시/수술 매뉴얼을 제작할 수 있습니다. 고객의 치료태그를 기반으로 바로 펼쳐지는 매뉴얼은 누가 응대하더라도 오안내 없이 일관된 상담 퀄리티를 유지할 수 있게 합니다.'
            return options
        },
        option05: function() {
            let options = JSON.parse(JSON.stringify(this.defaultVideoOptions))
            options.sources[0].src = 'https://afterdoc-video.s3.ap-northeast-2.amazonaws.com/homepage/intro/%EC%98%88%EC%95%BD%ED%95%98%EA%B8%B0.mp4'
            options.additionalTitle = '예약관리'
            options.additionalDesc = '고객 문의에 대한 상담은 예약으로 연결되어야 합니다. 병의원 운영상황에 최적화된 예약기능은 상담부터 예약까지 한 화면에서 진행해 업무 효율을 높일 수 있습니다.'
            return options
        },
        option06: function() {
            let options = JSON.parse(JSON.stringify(this.defaultVideoOptions))
            options.sources[0].src = 'https://afterdoc-video.s3.ap-northeast-2.amazonaws.com/homepage/intro/%EB%B2%88%EC%97%AD.mp4'
            options.additionalTitle = '채팅번역'
            options.additionalDesc = '늘어가는 외국인 환자들의 문의, 어떤 언어로 질문이 오더라도 애프터닥을 통해 응대할 수 있습니다. 의료서비스에 특화된 번역기능을 사용해 한국어로 편하게 응대하세요.'
            return options
        }
    },
    mounted() {
        this.floatingButtonObserver = new IntersectionObserver(this.handleIntersection, {
            root: null,
            threshold: 0.9
        })
        if (this.$refs?.floatingButton) {
            this.floatingButtonObserver.observe(this.$refs?.floatingButton)
        }
    },
    methods: {
        action_DeselectVideo() {
            this.selectedOptions = undefined
        },
        action_SelectVideo(options) {
            this.selectedOptions = options
        },
        action_Contact() {
            this.$router.push({name: 'ContactPage', params: {key: 'intro'}})
        },

        handleIntersection(entries) {
            entries.forEach(entry => {
                this.isShowFloatingButton = !entry.isIntersecting
            })
        }
    },
    beforeUnmount() {
        if (this.floatingButtonObserver && this.refs?.floatingButton) {
            this.observer.unobserve(this.$refs?.floatingButton)
        }
    }
}
</script>


<style scoped>
@import '../../assets/css/intro.css';
</style>