<template>
    <header style="position: sticky;" class="contact_header">
        <div class="ham_btn" @click="toggleSiteMap">
            <div class="btn btn01"></div>
            <div class="btn btn02"></div>
            <div class="btn btn03"></div>
        </div>

        <a class="center" href="https://afterdoc.co.kr/">
            <img src="@/assets/img/saas/logo_v2.svg" alt="애프터닥 로고 이미지 | 메디팔" />
        </a>
    </header>

    <div class="contact_wrap flex">
        <div class="contact_info flex">
            <div class="contact_title flex">
                <div class="title">
                    <h1><span>애프터닥</span> 세일즈팀과 만나보세요</h1>
                    <p>애프터닥으로 비즈니스 목표를 달성하는 방법을 알아보세요.</p>
                </div>
                <div class="contact_type flex">
                    <div class="type_call flex_aic">
                        <span class="flex_aic">
                            <img src="@/assets/img/new_contact/call.svg" alt="">
                            <p>전화 상담</p>
                        </span>
                        <div>소요시간 5분</div>
                    </div>
                    <div class="type_meeeting flex_aic">
                        <span class="flex_aic">
                            <img src="@/assets/img/new_contact/meeting.svg" alt="">
                            <p>방문 상담</p>
                        </span>
                        <div>소요시간 10분</div>
                    </div>
                </div>
            </div>
            <div class="meeting_agenda">
                <h2>미팅 아젠다</h2>
                <div class="agenda_list flex">
                    <div class="agenda_list_1 flex_aic">
                        <img src="@/assets/img/new_contact/check.svg" alt="">
                        <span>애프터닥에 대한 자세한 설명</span>
                    </div>
                    <div class="agenda_list_2 flex_aic">
                        <img src="@/assets/img/new_contact/check.svg" alt="">
                        <span>우리 병원의 경영 목표에 맞는 맞춤형 전략 논의</span>
                    </div>
                    <div class="agenda_list_3 flex_aic">
                        <img src="@/assets/img/new_contact/check.svg" alt="">
                        <span>도입 플랜과 견적 안내</span>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="contact_survey">
            <!-- done -->
            <div class="done_bg" v-if="isDone">
            <!-- <div class="done_bg"> -->
                <img src="@/assets/img/contact/check.png" alt="완료" />
            </div>

            <!-- indicator -->
            <div class="indicator_bg" v-if="isLoading">
                <span class="indicator"></span>
            </div>

            <div class="survey_title">
                <p>아래 양식을 입력하시고 <span>무료 상담</span>을 예약하세요</p>
                <p>최대한 신속하게 연락드리겠습니다.</p>
            </div>
            <div class="survey_list_wrap flex">
                <div class="survey_list">
                    <p>성함을 입력해 주세요.<span class="red">*</span></p>
                    <div class="survey_input_wrap">
                        <input type="text" placeholder="예)김닥터" v-model="userName">
                    </div>
                    <p class="red" v-if="!hasUserName">필수 입력 항목입니다.</p>
                </div>
                <div class="survey_list">
                    <p>연락 가능한 연락처를 남겨주세요.<span class="red">*</span></p>
                    <div class="survey_input_wrap">
                        <input type="text" placeholder="숫자만 입력해 주세요." v-model="userPhone">
                    </div>
                    <p class="red" v-if="!hasUserPhone">필수 입력 항목입니다.</p>
                    <p class="red" v-if="(hasUserPhone && !isPhone) && needCheckEmpty">올바른 전화번호를 입력해주세요.</p>
                </div>
                <div class="survey_list">
                    <p>병·의원명<span class="red">*</span></p>
                    <div class="survey_input_wrap">
                        <input type="text" placeholder="예)애프터닥의원" v-model="hospitalName">
                    </div>
                    <div class="scheduled_open flex_aic mgt_5">
                        <input type="checkbox" id="개원 예정" v-model="willOpen">
                        <label for="개원 예정">개원 예정</label>
                    </div>
                    <p class="red" v-if="!hasHospitalName">필수 입력 항목입니다.</p>
                </div>
                <div class="survey_list">
                    <p>지역<span class="red">*</span></p>
                    <div class="survey_input_wrap">
                        <input type="text" placeholder="예)서울시 강남구 대치동" v-model="location">
                    </div>
                    <p class="red" v-if="!hasLocation">필수 입력 항목입니다.</p>
                </div>
                <button class="submit" @click="action_Send">상담 신청</button>
                <div class="postscript">
                    *제출하신 상담 신청서는 절대 외부로 공유되지 않으며, 오직 도입 상담을 위한 준비과정에서만 사용됩니다.
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import * as Utils from '../../../utils/utils'

const Email_Subject = "[홈페이지 문의]"
const Email_Subject_AL = "[전능 홈페이지 문의]"

export default {
    data() {
        return {
            userName: '',
            userPhone: '',
            hospitalName: '',
            location: '',
            willOpen: false,

            needCheckEmpty: false,

            isLoading: false,
            isDone: false,
        }
    },
    computed: {
        queryParams: function() {
            return this.$route.params
        },
        fromALEvent: function() {
            // TODO: - 
            if (this.$route.params?.key === 'event/al') {
                return true
            }
            return false
        },

        hasUserName: function() {
            return this.userName != undefined && this.userName != '' || !this.needCheckEmpty
        },
        hasUserPhone: function() {
            return this.userPhone != undefined && this.userPhone != '' || !this.needCheckEmpty
        },
        hasHospitalName: function() {
            return this.hospitalName != undefined && ((this.hospitalName != '' || !this.needCheckEmpty) || this.willOpen)
        },
        hasLocation: function() {
            return this.location != undefined && this.location != '' || !this.needCheckEmpty
        },
        isPhone: function() {
            if (!this.hasUserPhone) { return false }
            let regex = /^(\d{2}|\d{3})(?:\d{3}|\d{4})\d{4}$/
            return regex.test(this.userPhone)
        },
        canSend: function() {
            return this.hasUserName && this.isPhone && this.hasHospitalName && this.hasLocation
        },
        mailTitle: function() {
            return ''
        },
    },
    methods: {
        initVariables() {
            this.isLoading = false
            this.isDone = false
            this.userName = ''
            this.userPhone = ''
            this.hospitalName = ''
            this.location = ''
            this.willOpen = false
            this.needCheckEmpty = false
        },
        async action_Send() {
            this.needCheckEmpty = true
            if (this.canSend == false) { return }

            // let title = '[' + this.$attrs.identifier + ']'
            let title = ''
            let identifier = this.queryParams?.key
            if (identifier != undefined && identifier != '') {
                title = '[' + this.queryParams.key + ']'
            }
            title += this.fromALEvent ? Email_Subject_AL : Email_Subject
            let body = '이름 : \n\t' + this.userName + '\n\n'
            body += '연락가능한 전화번호 : \n\t' + this.userPhone + '\n\n'
            body += '병·의원명 : \n\t' + this.hospitalName + '\n\n'
            body += '지역 : \n\t' + this.location + '\n\n'
            body += '개원예정 : ' + (this.willOpen ? 'O' : 'X') + '\n\n'

            body += await Utils.getBrowserInformation()
            try {
                this.isLoading = true
                await Utils.sendEmail(title, body)
                //TODO: Google 전환코드 삽입
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({'event': 'consulting_submit'});
                // window.fbq('track', 'CompleteRegistration')

                try {
                    // await Utils.sendSMS(this.userName, this.userPhone)
                } catch(err) {
                    console.log(err)
                }
                
                this.isLoading = false
                this.isDone = true
                setTimeout(() => {
                    this.initVariables()
                }, 2000);
            } catch(err) {
                this.initVariables()
                // alert(err)
            }
        }
    }
}
</script>


<style>
/* ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ공통ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ */
input{
    box-sizing: border-box;
}
.gap_40{
    gap: 40px;
}
.mgt_5{
    margin-top: 5px;
}
.flex{
    display: flex;
}
.flex_aic{
    display: flex;
    align-items: center;
}
.red{
    color: #FF5C71;
}
input[type="radio"],
input[type="checkbox"]{
    cursor: pointer;
}

input[type="checkbox"]{
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #dfdfe0;
    background-color: white;
    border-radius: 6px;
    position: relative;
}

input[type="checkbox"]:checked{
    background-color: #2360FF;
    border: none;
}

input[type="checkbox"]:checked::before {
    content: "";
    transition: 0.14s;
    position: absolute;
    left: 6px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    animation: check 0.22s ease-in-out;
}
@keyframes check{
    0%{
        top:15px
    }
    50%{
        top:-2px
    }
    100%{
        top: 0;
    }
}

input[type="radio"]{
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #dfdfe0;
    border-radius: 50%;
}

input[type="radio"]:checked{
    border-color: #2360FF;
}
input[type="radio"]:checked::before {
    content: "";
    transition: 0.14s;
    position: absolute;
    left: 3px;
    top: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #2360FF;
    animation: radio 0.22s ease-in-out;
}
@keyframes radio{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

label{
    cursor: pointer;
    margin-left: 6px;
}

/* 필수 입력 칸이 빈 칸인 채로 상담 신청을 눌렀을 때 나타난다 */
p.red{
    font-size: 14px !important;
    font-weight: 400 !important;
    margin-top: 5px;
    /* display: none; */
}
/* ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ공통ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ */


/* ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ왼쪽 시작ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ */
.contact_wrap img{
    width: 24px;
    height: 24px;
}

.contact_wrap{
    padding: 100px 40px;
    justify-content: space-between;
    max-width: 1280px;
    min-height: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    line-height: 1.5;
}
.contact_wrap .contact_info{
    flex-direction: column;
    gap: 50px;
}

.contact_wrap .contact_info .contact_title{
    flex-direction: column;
    gap: 30px;
}
.contact_wrap .contact_info .contact_title .title h1{
    font-size: 36px;
    font-weight: 700;
}

.contact_wrap .contact_info .contact_title .title h1 span{
    color: #2360FF;
}
.contact_wrap .contact_info .contact_title .title p{
    font-size: 18px;
    color: var(--color_6c);
    margin-top: 10px;
}
.contact_wrap .contact_info .contact_title .contact_type{
    flex-direction: column;
    gap: 10px;
}

.contact_wrap .contact_info .contact_title .contact_type div{
    gap: 12px;
}

.contact_wrap .contact_info .contact_title .contact_type span{
    color: #2360FF;
    font-weight: 700;
}
.contact_wrap .contact_info .contact_title .contact_type span p{
    margin-left: 6px;
}

.contact_wrap .contact_info .meeting_agenda h2{
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
}

.contact_wrap .contact_info .meeting_agenda .agenda_list{
    flex-direction: column;
    gap: 12px;
    font-size: 18px;
}
.contact_wrap .contact_info .meeting_agenda .agenda_list div{
    gap: 10px;
}

/* ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ왼쪽 끝ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ */


/* ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ오른쪽 시작ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ */

.contact_wrap .contact_survey{
    height: fit-content;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0px 1px 15px 0px rgba(110, 110, 110, 0.25);
    overflow: hidden;
}

.contact_wrap .contact_survey .survey_title{
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 30px;
}
.contact_wrap .contact_survey .survey_title span{
    color: #2360FF;
    font-weight: 700;
}
.contact_wrap .contact_survey .survey_list_wrap{
    flex-direction: column;
    gap: 20px;
}
.contact_wrap .contact_survey .survey_list p{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
}
.contact_wrap .contact_survey .survey_list input[type="text"]{
    padding: 0px 12px;
    height: 40px;
    border: 1px solid #dfdfe0;
    border-radius: 8px;
    width: 100%;
}

.contact_wrap .contact_survey .survey_list_wrap button.submit{
    font-size: 16px;
    color: white;
    font-weight: 500;
    background-color: #2360FF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    line-height: 1.5;
    padding: 10px 0;
    transition: 0.1s ease-in-out;
}
.contact_wrap .contact_survey .survey_list_wrap button.submit:hover{
    background-color: #1e51d2;
}
.contact_wrap .contact_survey .survey_list_wrap button.submit:active{
    background-color: #005ae2;
}

.contact_wrap .contact_survey .survey_list_wrap .postscript{
    font-size: 14px;
    color: var(--color_999);
}
.contact_wrap .contact_survey .survey_list_wrap .scheduled_open{
    font-size: 14px;
    color: var(--color_6c);
}
/* ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ오른쪽 끝ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ */


/* ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ상담신청 후 인디케이터/신청완료 시작ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ */
.contact_survey .done_bg{
    z-index: 5;
    background-color: white;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    animation: done 1.8s ease-in-out;
}
.contact_survey .done_bg img{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    width: 80px;
    height: 80px;
    animation: done 1.8s ease-in-out;
}
@keyframes done {
    0%{
        opacity: 0;
    }
    25%{
        opacity: 1;
    }
    75%{
        opacity: 1;
    }
    95%{
        opacity: 0;
    }
    100%{
        opacity: 0;
    }
}

.contact_survey .indicator_bg{
    z-index: 8;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    align-items: center;
    justify-content: center;
    display: flex;
}

.contact_survey .indicator {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    animation: rotates 1s linear infinite;
}
.contact_survey .indicator::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #2360FF;
    animation: spinners 2s linear infinite;
}

@keyframes rotates {
    100% {
    transform: rotate(360deg);
    }
}

@keyframes spinners {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
    25% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }
    75% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    }
    100% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
}


/* ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ상담신청 후 인디케이터 및 완료 끝ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ */
@media(max-width:1280px){
    .contact_wrap{
        flex-direction: column;
        gap: 50px;
        padding: 100px ;
    }
    .contact_header .ham_btn{
        display: none;
    }
}

@media(max-width:960px){
    .contact_wrap{
        padding: 60px 40px ;
    }
    .contact_header .ham_btn{
        display: none;
    }
}
@media(max-width:440px){
    .contact_wrap{
        padding: 60px 24px ;
    }
    .contact_wrap .contact_info .contact_title .title h1{
        font-size: 30px;
        word-break: keep-all;
    }
    .contact_wrap .contact_info .contact_title .title p{
        font-size: 16px;
        word-break: keep-all;
    }
    .contact_wrap .contact_info .meeting_agenda .agenda_list{
        font-size: 15px;
        gap: 8px;
    }
    .contact_wrap .contact_survey{
        padding: 20px
    }
    .contact_wrap .contact_survey .survey_title{
        font-size: 16px;
        word-break: keep-all;
    }
    .contact_wrap .contact_survey .survey_list p{
        font-size: 14px;
    }
    .contact_wrap .contact_survey .survey_list_wrap button.submit{
        font-size: 14px;
    }
    .contact_wrap .contact_survey .survey_list_wrap .postscript{
        font-size: 12px;
    }
}


</style>