<template>
    <!-- step1 -->
    <div class="regist_form_step_wrap">
        <div class="regist_scroll_wrap">
            <div class="regist_form_top">
                <div class="title">
                    <div class="status_txt">
                        <div class="ltitle">카카오 알림톡</div>
                        <div class="mtitle">1. 신청자 확인</div>
                    </div>
                </div>
                <div class="status">
                    <span class="circle on"></span>
                    <span class="circle"></span>
                </div>
            </div>
            <div class="regist_form_box">
                <div class="regist_scroll">
                    <div class="form_set">
                        <b class="form_title">병원명<span class="red_txt">*</span></b>
                        <input type="text" placeholder="병원명 입력" v-model="tempHospitalName">
                        <small class="err" v-if="isEmptyHospitalName">병원명을 입력해 주세요.</small>
                    </div>
                    <div class="form_set">
                        <b class="form_title">신청자명<span class="red_txt">*</span></b>
                        <input type="text" placeholder="신청자명 입력" v-model="realName">
                        <small class="err" v-if="isEmptyRealName">연락 받으실 분의 성함을 입력해 주세요.</small>
                    </div>
                    <div class="form_set">
                        <b class="form_title">신청자 연락처<span class="red_txt">*</span></b>
                        <input type="text" placeholder="연락 받으실 휴대폰번호 입력" v-model="phone">
                        <small class="err" v-if="isEmptyPhone">연락 받으실 분의 휴대폰번호를 입력해 주세요.</small>
                    </div>
                </div>
            </div>    
        </div>
        <div class="regist_button_wrap">
            <button class="btn_regist_back" @click="action_Back">
                <img src="@/assets/img/regist_thirdparty/ic_regist_arrow_left.png" alt="" @click="action_Back">
                이전으로
            </button>
            <button class="btn_regist_blue" @click="action_Next">다음으로</button>
        </div>
    </div>
</template>


<script>
export default {
    props: {
        model: {
            type: Object,
            default: () => { return {} }
        }
    },
    data() {
        return {
            isChecked: false,

            tempHospitalName: undefined,
            realName: undefined,
            phone: undefined,
        }
    },
    watch: {
        model: {
            immediate: true,
            handler: function(newValue) {
                if (newValue === undefined) { return }
                this.tempHospitalName = newValue.tempHospitalName
                this.realName = newValue.realName
                this.phone = newValue.phone
            }
        },
        tempHospitalName: {
            handler: function(newValue, oldValue) {
                if (newValue == oldValue) { return }
                this.update()
            }
        },
        realName: {
            handler: function(newValue, oldValue) {
                if (newValue == oldValue) { return }
                this.update()
            }
        },
        phone: {
            handler: function(newValue, oldValue) {
                if (newValue == oldValue) { return }
                this.update()
            }
        },
    },
    computed: {
        isEmptyHospitalName: function() {
            if (this.isChecked == false) { return false }
            return this.tempHospitalName == undefined || this.tempHospitalName == ''
        },
        isEmptyRealName: function() {
            if (this.isChecked == false) { return false }
            return this.realName == undefined || this.realName == ''
        },
        isEmptyPhone: function() {
            if (this.isChecked == false) { return false }
            return this.phone == undefined || this.phone == ''
        },
        canNext: function() {
            if (this.isChecked == false) { return false }
            return !this.isEmptyHospitalName && !this.isEmptyRealName && !this.isEmptyPhone
        }
    },
    methods: {
        // MARK: - Actions
        action_Back() {
            this.$emit('back')
        },
        action_Next() {
            this.isChecked = true
            if (this.canNext) {
                this.$emit('next')
            }
        },

        // MARK: - Emit
        update() {
            this.$emit('update', {
                tempHospitalName: this.tempHospitalName,
                realName: this.realName,
                phone: this.phone
            })
        }
    }
}
</script>
