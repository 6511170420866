<template>
    <div class="saas_main">
        <!-- <a class="top_banner" href="https://www.afterdoc.co.kr/contact/home">
            <div class="top_banner_txt">애프터닥이 궁금하신가요? 제안서를 바로 받아보세요</div>
            <div>
                <img src="@/assets/img/saas/chevron.png" alt="화살표">
            </div>
            <div class="contact_btn">제안서 받아보기</div>
        </a> -->
        <div class="header">
            <div class="saas_header">
                <div class="header_left">
                    <a href="#" class="header_logo">
                        <img src="@/assets/img/saas/logo_v2.svg" alt="애프터닥 로고">
                    </a>
                    <div class="header_menu">
                        <a href="https://medipal.co.kr/">회사소개</a>
                        <a href="https://blog.afterdoc.co.kr/">블로그</a>
                        <div>
                            <input type="checkbox" id="faq_toggle_pc" class="faq_toggle"/>
                            <label for="faq_toggle_pc" class="faq_label">자주묻는질문</label>
                            <div class="faq_overlay"></div>
                            <div class="faq_section">
                                <div class="faq_title flex_aic_spb">
                                    <div></div>
                                    <div class="">자주묻는질문</div>
                                    <label for="faq_toggle_pc" class="close_faq_btn flex_aic_jcc">
                                        <span></span>
                                        <span></span>
                                    </label>
                                </div>
                                <div class="faq_content">
                                    <div class="faq_item">
                                        <input type="checkbox" id="faq_1_pc" class="faq_toggle">
                                        <label for="faq_1_pc" class="faq_label">
                                            <div class="faq_question flex_aic_spb">
                                                <div><span class="main_blue_ft">[도입 문의]</span> 서비스/가격 등 궁금한 점이 있습니다.</div>
                                                <div class="arrow_wrap flex_aic_jcc"><span class="arrow"></span></div>
                                            </div>
                                            <div class="faq_answer">
                                                <div class="faq_answer_row1">애프터닥은 도입을 원하시는 병·의원의 규모와 진료 분야에 따라 커스터마이징 되어 제공되는 솔루션입니다. 도입을 원하신다면 방문하여 상담을 통해 서비스 방향을 결정하고 가격이 책정됩니다.</div><br>
                                                아래 단계에 따라 문의 주신다면 확인 후 빠르게 답변드리도록 하겠습니다.
                                                <br>
                                                1. 애프터닥 홈페이지 상단에 있는 <span class="main_blue_ft">[상담 신청]</span>을 통해 문의해 주세요.<br>
                                                2. 홈페이지 내 온라인 문의는 연락처를 정확하게 입력해 주셔야 회신드릴 수 있습니다.<br>
                                                3. 문의사항은 담당자 확인 후 영업일 기준 0~1일 내 답변을 드립니다.
                                            </div>
                                        </label>
                                    </div>
                                    <div class="faq_item">
                                        <input type="checkbox" id="faq_2_pc" class="faq_toggle">
                                        <label for="faq_2_pc" class="faq_label">
                                            <div class="faq_question flex_aic_spb">
                                                <div><span class="main_blue_ft">[도입 문의]</span> 애프터닥을 이용하고 싶습니다.</div>
                                                <div class="arrow_wrap flex_aic_jcc"><span class="arrow"></span></div>
                                            </div>
                                            <div class="faq_answer">
                                                <div class="faq_answer_row1">
                                                    애프터닥에 관심 가져 주셔서 감사합니다.
                                                    애프터닥의 도입은 홈페이지 내 <span class="main_blue_ft">[상담 신청]</span>을 통해 신청하실 수 있습니다.
                                                    아래 단계에 따라 문의 주신다면 확인 후 빠르게 답변드리도록 하겠습니다.
                                                </div><br>
                                                1. 애프터닥 홈페이지 상단에 있는 <span class="main_blue_ft">[상담 신청]</span>을 눌러 문의해 주세요. <br>
                                                2. 홈페이지 내 온라인 문의는 연락처를 정확하게 입력해 주셔야 회신드릴 수 있습니다. <br>
                                                3. 문의사항은 담당자 확인 후 영업일 기준 0~1일 내 답변을 드립니다.
                                            </div>
                                        </label>
                                    </div>
                                    <div class="faq_item">
                                        <input type="checkbox" id="faq_3_pc" class="faq_toggle">
                                        <label for="faq_3_pc" class="faq_label">
                                            <div class="faq_question flex_aic_spb">
                                                <div><span class="main_blue_ft">[서비스 문의]</span> 애프터닥 소프트웨어는 어떻게 구성되어 있나요?</div>
                                                <div class="arrow_wrap flex_aic_jcc"><span class="arrow"></span></div>
                                            </div>
                                            <div class="faq_answer">
                                                <div class="faq_answer_row1">애프터닥에서 제공하는 소프트웨어는 병·의원에 개별적으로 제공되는 플랫폼을 관리, 운영할 수 있는 소프트웨어입니다. 제공받으신 플랫폼은 해당 병·의원에 내원한 환자들만이 가입이 가능하며, 환자분들은 스마트폰 Application을 통해 그리고 병·의원에서는 관리용 PC프로그램을 통해 사용하실 수 있습니다. </div><br>
                                                더 궁금하신 사항이 있으시다면 홈페이지 상단의 <span class="main_blue_ft">[상담 신청]</span>을 통해 남겨주세요.<div class="br"></div>
                                                확인 후 빠르게 담당자가 답변 드리도록 하겠습니다.
                                            </div>
                                        </label>
                                    </div>
                                    <div class="faq_item">
                                        <input type="checkbox" id="faq_4_pc" class="faq_toggle">
                                        <label for="faq_4_pc" class="faq_label">
                                            <div class="faq_question flex_aic_spb">
                                                <div><span class="main_blue_ft">[서비스 문의]</span> 기존 EMR/CRM 프로그램을 사용중이었는데, 무엇이 다른가요?</div>
                                                <div class="arrow_wrap flex_aic_jcc"><span class="arrow"></span></div>
                                            </div>
                                            <div class="faq_answer">
                                                <div class="faq_answer_row1">병·의원에서 기존 사용하시던 EMR/CRM 프로그램과 애프터닥에서 제공하는 소프트웨어는 별도로 설치 운영됩니다. 애프터닥의 소프트웨어는 병·의원의 환자 자산을 확보하기 위한 플랫폼을 운영하기 위한 소프트웨어로써, 기록을 위한 EMR/CRM과는 다른 목적을 가지고 있습니다. </div><br>
                                                더 궁금하신 사항이 있으시다면 홈페이지 상단의 <span class="main_blue_ft">[상담 신청]</span>을 남겨주세요. <br> 
                                                확인 후 빠르게 담당자가 연락드리도록 하겠습니다.
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="header_right flex_aic">
                    <a href="https://www.afterdoc.co.kr/contact/home" class="contact_btn" >상담 신청</a>

                    <div class="header_ham_menu">
                        <div class="ham_btn_wrap flex_aic_jcc">
                            <input type="checkbox" id="menu_toggle" class="menu_toggle"/>
                            <label for="menu_toggle" class="menu_btn">
                                <span></span>
                                <span></span>
                                <span></span>
                            </label>
                            <div class="saas_sitemap">
                                <a href="https://medipal.co.kr/">회사소개</a>
                                <a href="https://blog.afterdoc.co.kr/">블로그</a>
                                <div>
                                    <input type="checkbox" id="faq_toggle_mo" class="faq_toggle"/>
                                    <label for="faq_toggle_mo" class="faq_label">자주묻는질문</label>
                                    <div class="faq_overlay"></div>
                                    <div class="faq_section">
                                        <div class="faq_title flex_aic_spb">
                                            <div></div>
                                            <div class="">자주묻는질문</div>
                                            <label for="faq_toggle_mo" class="close_faq_btn flex_aic_jcc">
                                                <span></span>
                                                <span></span>
                                            </label>
                                        </div>
                                        <div class="faq_content">
                                            <div class="faq_item">
                                                <input type="checkbox" id="faq_1_mo" class="faq_toggle">
                                                <label for="faq_1_mo" class="faq_label">
                                                    <div class="faq_question flex_aic_spb">
                                                        <div><span class="main_blue_ft">[도입 문의]</span> 서비스/가격 등 궁금한 점이 있습니다.</div>
                                                        <div class="arrow_wrap flex_aic_jcc"><span class="arrow"></span></div>
                                                    </div>
                                                    <div class="faq_answer">
                                                        <div class="faq_answer_row1">애프터닥은 도입을 원하시는 병·의원의 규모와 진료 분야에 따라 커스터마이징 되어 제공되는 솔루션입니다. 도입을 원하신다면 방문하여 상담을 통해 서비스 방향을 결정하고 가격이 책정됩니다.</div><br>
                                                        아래 단계에 따라 문의 주신다면 확인 후 빠르게 답변드리도록 하겠습니다.
                                                        <br>
                                                        1. 애프터닥 홈페이지 상단에 있는 <span class="main_blue_ft">[상담 신청]</span>을 통해 문의해 주세요.<br>
                                                        2. 홈페이지 내 온라인 문의는 연락처를 정확하게 입력해 주셔야 회신드릴 수 있습니다.<br>
                                                        3. 문의사항은 담당자 확인 후 영업일 기준 0~1일 내 답변을 드립니다.
                                                    </div>
                                                </label>
                                            </div>
                                            <div class="faq_item">
                                                <input type="checkbox" id="faq_2_mo" class="faq_toggle">
                                                <label for="faq_2_mo" class="faq_label">
                                                    <div class="faq_question flex_aic_spb">
                                                        <div><span class="main_blue_ft">[도입 문의]</span> 애프터닥을 이용하고 싶습니다.</div>
                                                        <div class="arrow_wrap flex_aic_jcc"><span class="arrow"></span></div>
                                                    </div>
                                                    <div class="faq_answer">
                                                        <div class="faq_answer_row1">
                                                            애프터닥에 관심 가져 주셔서 감사합니다.
                                                            애프터닥의 도입은 홈페이지 내 <span class="main_blue_ft">[상담 신청]</span>을 통해 신청하실 수 있습니다.
                                                            아래 단계에 따라 문의 주신다면 확인 후 빠르게 답변드리도록 하겠습니다.
                                                        </div><br>
                                                        1. 애프터닥 홈페이지 상단에 있는 <span class="main_blue_ft">[상담 신청]</span>을 눌러 문의해 주세요. <br>
                                                        2. 홈페이지 내 온라인 문의는 연락처를 정확하게 입력해 주셔야 회신드릴 수 있습니다. <br>
                                                        3. 문의사항은 담당자 확인 후 영업일 기준 0~1일 내 답변을 드립니다.
                                                    </div>
                                                </label>
                                            </div>
                                            <div class="faq_item">
                                                <input type="checkbox" id="faq_3_mo" class="faq_toggle">
                                                <label for="faq_3_mo" class="faq_label">
                                                    <div class="faq_question flex_aic_spb">
                                                        <div><span class="main_blue_ft">[서비스 문의]</span> 애프터닥 소프트웨어는 어떻게 구성되어 있나요?</div>
                                                        <div class="arrow_wrap flex_aic_jcc"><span class="arrow"></span></div>
                                                    </div>
                                                    <div class="faq_answer">
                                                        <div class="faq_answer_row1">애프터닥에서 제공하는 소프트웨어는 병·의원에 개별적으로 제공되는 플랫폼을 관리, 운영할 수 있는 소프트웨어입니다. 제공받으신 플랫폼은 해당 병·의원에 내원한 환자들만이 가입이 가능하며, 환자분들은 스마트폰 Application을 통해 그리고 병·의원에서는 관리용 PC프로그램을 통해 사용하실 수 있습니다. </div><br>
                                                        더 궁금하신 사항이 있으시다면 홈페이지 상단의 <span class="main_blue_ft">[상담 신청]</span>을 통해 남겨주세요. <br >
                                                        확인 후 빠르게 담당자가 답변 드리도록 하겠습니다.
                                                    </div>
                                                </label>
                                            </div>
                                            <div class="faq_item">
                                                <input type="checkbox" id="faq_4_mo" class="faq_toggle">
                                                <label for="faq_4_mo" class="faq_label">
                                                    <div class="faq_question flex_aic_spb">
                                                        <div><span class="main_blue_ft">[서비스 문의]</span> 기존 EMR/CRM 프로그램을 사용중이었는데, 무엇이 다른가요?</div>
                                                        <div class="arrow_wrap flex_aic_jcc"><span class="arrow"></span></div>
                                                    </div>
                                                    <div class="faq_answer">
                                                        <div class="faq_answer_row1">병·의원에서 기존 사용하시던 EMR/CRM 프로그램과 애프터닥에서 제공하는 소프트웨어는 별도로 설치 운영됩니다. 애프터닥의 소프트웨어는 병·의원의 환자 자산을 확보하기 위한 플랫폼을 운영하기 위한 소프트웨어로써, 기록을 위한 EMR/CRM과는 다른 목적을 가지고 있습니다. </div><br>
                                                        더 궁금하신 사항이 있으시다면 홈페이지 상단의 <span class="main_blue_ft">[상담 신청]</span>을 남겨주세요. <br>
                                                        확인 후 빠르게 담당자가 연락드리도록 하겠습니다.
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section class="section_1">
            <div class="section_wrap">
                <div class="section_big_title">
                    <span class="main_blue_ft">체계적 환자관리가<br class="mo_br"> 최고의 마케팅입니다 <br class="mo_br"></span>
                    <br class="pc_br">재진마케팅 솔루션, 애프터닥
                </div>
            
                <div class="flex_aic_jcc mgt_30">
                    <a href="https://www.afterdoc.co.kr/contact/home" class="contact_btn">상담 신청 →</a>
                </div>
                <div class="img_cont flex_jcc">
                    <img src="@/assets/img/saas_re/section1.png" class="img_pc" alt="애프터닥 B2B SaaS 솔루션 이미지">
                    <img src="@/assets/img/saas_re/section1_mo.png" class="img_mo" alt="애프터닥 B2B SaaS 솔루션 이미지">
                </div>
            </div>
        </section>

        <section class="section_2">
            <div class="section_wrap">
                <div class="section_title">
                    원장님이 가진 최고의 마케팅<br class="mo_br"> 자산은<br class="pc_br">
                    광고매체가 아니라 <br class="mo_br"><span class="main_blue_ft">기존 환자들</span>입니다.
                </div>
                <div class="section_sub_title mgt_16">
                    광고집행 의존이 아닌 환자자산 100% 활용으로<br class="mo_br"> 매출을 다음 단계로 상승시켜보세요.
                </div>
                <div class="img_cont flex_jcc mgt_50">
                    <img src="@/assets/img/saas_re/section2.png" class="img_pc" alt="파이차트, 이탈환자 중 10%라도 다시 내원한다면? 기존대비 유입환자 15% 증가, 대부분의 병원은 방문환자의 40%로만 운영">
                    <img src="@/assets/img/saas_re/section2_mo.png" class="img_mo" alt="파이차트, 이탈환자 중 10%라도 다시 내원한다면? 기존대비 유입환자 15% 증가, 대부분의 병원은 방문환자의 40%로만 운영">
                </div>
            </div>
        </section>

        <section class="section_3">
            <div class="section_wrap">
                <div class="section_title">
                    <span class="count_num">200개</span>병원에서 <br class="mo_br">평균매출 19.7% 상승, <br class="mo_br">단골고객 재방문수 2.8배 상승!<br class="pc_br">
                    <span class="main_blue_ft"> <br class="mo_br">검증된 노하우가 담긴 <br class="mo_br">국내 최초의 재진마케팅 솔루션</span>
                </div>
                <div class="img_cont flex_jcc mgt_50">
                    <img src="@/assets/img/saas_re/section3.png" class="img_pc" alt="애프터닥 등록 특허 3건 특허증 이미지">
                    <img src="@/assets/img/saas_re/section3_mo.png" class="img_mo" alt="애프터닥 등록 특허 3건 특허증 이미지">
                </div>
            </div>
        </section>

        <div class="pdx_20">
            <div class="page_divider section_title flex_aic_jcc main_blue_ft">
                01. 체계적 환자관리 시스템 구축
            </div>
        </div>

        <section class="section_4">
            <div class="section_wrap">
                <div class="section_big_title">
                    진료과는 하나여도<br>
                    <span class="main_blue_ft">방문환자의 니즈는 다 다릅니다</span>
                </div>
                <div class="section_sub_title mgt_16">
                    단 한번의 클릭으로 모든 환자에게 <br class="mo_br">맞춤형 관리를 자동으로 진행합니다.
                </div>
                <div class="video_cont flex_aic_spb mgt_50">
                    <div class="flex_column gap_20 pdl_30">
                        <div class="video_title">
                            환자별 니즈에 맞는 메시지를<br>
                            선제적으로 발송하여, 자연스러운<br>
                            상담이 이어지도록 합니다.
                        </div>
                        <div class="video_description">
                            병원의 치료유형과 주기에 맞춰<br>
                            환자관리 메시지를 알고리즘으로 만듭니다.
                        </div>
                    </div>
                    <div class="video_wrap">
                        <video src="@/assets/img/saas_re/video1.mp4" autoplay muted loop playsinline></video>
                    </div>
                </div>
            </div>
        </section>
        <section class="section_4">
            <div class="section_wrap">
                <div class="section_big_title">
                    <span class="main_blue_ft">입사 1일차도</span> 신속하고<br>
                    정확한 상담이 가능하도록
                </div>
                <div class="section_sub_title mgt_16">
                    환자의 치료내역과 연동된 상담 매뉴얼 세팅으로 <br class="mo_br">인수인계 업무에서 자유로워집니다.
                </div>
                <div class="video_cont flex_aic gap_50 mgt_50">
                    <div class="video_wrap">
                        <video src="@/assets/img/saas_re/video2.mp4" autoplay muted loop playsinline></video>
                    </div>
                    <div class="flex_column gap_20">
                        <div class="video_title">
                            상담고객 예약전환의 핵심은<br>
                            신속하고 정확한 응대입니다.
                        </div>
                        <div class="video_description">
                            우리 병원 시·수술 프로그램에 맞춰<br>
                            직원 누구나 사용할 수 있는<br>
                            상담매뉴얼을 세팅합니다.
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section_5">
            <div class="top_overlay"></div>
            <div class="bottom_overlay"></div>
            <div class="flex_column gap_160">
                <div class="section_wrap">
                    <div class="section_big_title">
                        애프터닥은 소프트웨어가 아니라<br>
                        <span class="main_blue_ft">노하우를 이식합니다.</span>
                    </div>
                    <div class="flex_column gap_24 mgt_50">
                        <div class="point_cont flex_aic_spb pdl_50">
                            <div class="flex_column flex_jcc gap_20 pdr_30">
                                <div class="cont_sub">
                                    <span class="count_num">3년간 20명</span>의 상담실장을 고용하고,<br>
                                    <span class="count_num">25만건</span>의 환자상담을 통한 노하우를 전달합니다.
                                </div>
                                <div class="cont_title">
                                    Top-Tier 병원급 환자관리 시스템을<br>
                                    최단시간 내 구축하는 방법,<br>
                                    지금 애프터닥을 만나보세요.
                                </div>
                            </div>
                            <div class="img_cont">
                                <div class="shadow_overlay"></div>
                                <img src="@/assets/img/saas_re/section5.png" alt="치열하게 회의중인 이미지">
                            </div>
                        </div>
                        <div class="icon_cont flex_aic_spb gap_24">
                            <div class="icon_wrap flex_column gap_40">
                                <div class="min_sub">통증, 뷰티, 치과, 내과 등 모든<br>
                                    진료과목에서 사용중인 애프터닥</div>
                                <div class="flex_column">
                                    <div class="min_title">도입병원 수</div>
                                    <div class="min_stats">200개<span class="count_num"></span></div>
                                </div>
                                <img src="@/assets/img/saas_re/section5_ic_1.png" alt="모든 진료과목 아이콘" class="ic1">
                            </div>
                            <div class="icon_wrap flex_column gap_40">
                                <div class="min_sub">환자의 만족도를<br>높이는 솔루션, 애프터닥</div>
                                <div class="flex_column">
                                    <div class="min_title">환자상담 진행 건수</div>
                                    <div class="min_stats">25만건<span class="count_num"></span></div>
                                </div>
                                <img src="@/assets/img/saas_re/section5_ic_2.png" alt="모든 진료과목 아이콘" class="ic2">
                            </div>
                            <div class="icon_wrap flex_column gap_40">
                                <div class="min_sub">병원에 대한 신뢰로<br>재방문을 만드는, 애프터닥</div>
                                <div class="flex_column">
                                    <div class="min_title">평균 재방문율 상승</div>
                                    <div class="min_stats">31%</div>
                                </div>
                                <img src="@/assets/img/saas_re/section5_ic_3.png" alt="모든 진료과목 아이콘" class="ic3">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="service_review">
                    <div class="review_overlay_left"></div>
                    <div class="review_overlay_right"></div>
                    <div class="section_title">
                        원장님들의 생생한 후기를<br class="mo_br"> 확인해 보세요
                    </div>

                    <div class="review_belt_wrap flex gap_30 mgt_50">
                        <div class="review_item flex_column">
                            <div class="review_txt">
                                수많은 치료프로그램에 맞춰 상담 프로세스를 구축하고, 자동으로 나가게끔까지 만들다 보니 더이상 불안해 할 이유가 없어졌습니다.
                            </div>
                            <div class="review_writer flex_aic_spb">
                                <div>경기 성남시</div>
                                <div>L 피부과 원장님</div>
                            </div>
                        </div>
                        <div class="review_item flex_column">
                            <div class="review_txt">
                                환자분들에게 비용 문의가 들어왔을 때, 단순한 가격 안내를 넘어 환자 상황에 맞게 상담하고 추천해줄수 있게 되니까 만족도가 올라가고 매출이 증가하는게 피부로 느껴집니다.
                            </div>
                            <div class="review_writer flex_aic_spb">
                                <div>서울 강남구</div>
                                <div>B 뷰티의원 원장님</div>
                            </div>
                        </div>
                        <div class="review_item flex_column">
                            <div class="review_txt">
                                받은 수술에 맞춰 관리메세지를 일일이 보내는 걸 계속 시도하다가, 결국 애프터닥으로 해결하고 나니 한결 속이 시원합니다.
                            </div>
                            <div class="review_writer flex_aic_spb">
                                <div>서울 용산구</div>
                                <div>K 성형외과 원장님</div>
                            </div>
                        </div>
                        <div class="review_item flex_column">
                            <div class="review_txt">
                                초기상담의 어려움 때문에 놓치고 있는 신규환자들이 많아서 애프터닥을 도입했는데 내원율이 많이 올라갔네요. 비용대비 효과로 볼 때 너무 좋습니다.
                            </div>
                            <div class="review_writer flex_aic_spb">
                                <div>서울 강남구</div>
                                <div>T 뷰티의원 원장님</div>
                            </div>
                        </div>
                        <div class="review_item flex_column">
                            <div class="review_txt">
                                도수치료처럼 꾸준한 내원이 필요한 경우, 환자 상태별로 지속적인 관리가 필요한데 애프터닥 도입 후 치료실 예약율이 확연히 증가했습니다.
                            </div>
                            <div class="review_writer flex_aic_spb">
                                <div>대전광역시</div>
                                <div>B 신경외과 원장님</div>
                            </div>
                        </div>
                        <div class="review_item flex_column">
                            <div class="review_txt">
                                환자들에게 와야할 때, 추가로 약을 받아야 할 때 맞춰서 빠짐없이 안내가 나가니까 새어나가는 매출이 많이 줄어 좋습니다.
                            </div>
                            <div class="review_writer flex_aic_spb">
                                <div>대구광역시</div>
                                <div>C 한의원 원장님</div>
                            </div>
                        </div>
                        <div class="review_item flex_column">
                            <div class="review_txt">
                                매번 교육하기 힘들었는데, 애프터닥 도입이후에 누가 상담해도 상담퀄리티가 괜찮아지는 것 같아 너무 만족스럽습니다.
                            </div>
                            <div class="review_writer flex_aic_spb">
                                <div>서울 서초구</div>
                                <div>B 뷰티의원 원장님</div>
                            </div>
                        </div>
                        <div class="review_item flex_column">
                            <div class="review_txt">
                                수많은 치료프로그램에 맞춰 상담 프로세스를 구축하고, 자동으로 나가게끔까지 만들다 보니 더이상 불안해 할 이유가 없어졌습니다.
                            </div>
                            <div class="review_writer flex_aic_spb">
                                <div>경기 성남시</div>
                                <div>L 피부과 원장님</div>
                            </div>
                        </div>
                        <div class="review_item flex_column">
                            <div class="review_txt">
                                환자분들에게 비용 문의가 들어왔을 때, 단순한 가격 안내를 넘어 환자 상황에 맞게 상담하고 추천해줄수 있게 되니까 만족도가 올라가고 매출이 증가하는게 피부로 느껴집니다.
                            </div>
                            <div class="review_writer flex_aic_spb">
                                <div>서울 강남구</div>
                                <div>B 뷰티의원 원장님</div>
                            </div>
                        </div>
                        <div class="review_item flex_column">
                            <div class="review_txt">
                                받은 수술에 맞춰 관리메세지를 일일이 보내는 걸 계속 시도하다가, 결국 애프터닥으로 해결하고 나니 한결 속이 시원합니다.
                            </div>
                            <div class="review_writer flex_aic_spb">
                                <div>서울 용산구</div>
                                <div>K 성형외과 원장님</div>
                            </div>
                        </div>
                        <div class="review_item flex_column">
                            <div class="review_txt">
                                초기상담의 어려움 때문에 놓치고 있는 신규환자들이 많아서 애프터닥을 도입했는데 내원율이 많이 올라갔네요. 비용대비 효과로 볼 때 너무 좋습니다.
                            </div>
                            <div class="review_writer flex_aic_spb">
                                <div>서울 강남구</div>
                                <div>T 뷰티의원 원장님</div>
                            </div>
                        </div>
                        <div class="review_item flex_column">
                            <div class="review_txt">
                                도수치료처럼 꾸준한 내원이 필요한 경우, 환자 상태별로 지속적인 관리가 필요한데 애프터닥 도입 후 치료실 예약율이 확연히 증가했습니다.
                            </div>
                            <div class="review_writer flex_aic_spb">
                                <div>대전광역시</div>
                                <div>B 신경외과 원장님</div>
                            </div>
                        </div>
                        <div class="review_item flex_column">
                            <div class="review_txt">
                                환자들에게 와야할 때, 추가로 약을 받아야 할 때 맞춰서 빠짐없이 안내가 나가니까 새어나가는 매출이 많이 줄어 좋습니다.
                            </div>
                            <div class="review_writer flex_aic_spb">
                                <div>대구광역시</div>
                                <div>C 한의원 원장님</div>
                            </div>
                        </div>
                        <div class="review_item flex_column">
                            <div class="review_txt">
                                매번 교육하기 힘들었는데, 애프터닥 도입이후에 누가 상담해도 상담퀄리티가 괜찮아지는 것 같아 너무 만족스럽습니다.
                            </div>
                            <div class="review_writer flex_aic_spb">
                                <div>서울 서초구</div>
                                <div>B 뷰티의원 원장님</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section_wrap flex_column gap_80">
                    <div class="section_big_title">
                        이것저것 준비할 필요없이<br>
                        <span class="main_blue_ft"> 애프터닥과 함께라면 <br class="mo_br">바로 시작할 수 있습니다</span>
                    </div>
                    <div class="point_cont_2 flex_aic_spb pdx_50">
                        <div class="img_cont_2">
                            <img src="@/assets/img/saas_re/section5_2.png" alt="원내 직접 방문하여 병원직원 대상 교육중인 이미지" class="ic1">
                        </div>
                        <div class="flex_column flex_jcc gap_20 pdr_30">
                                <div class="cont_sub">
                                    원내 매뉴얼이 없어서, 상담 프로세스가<br>
                                    갖춰지지 않아서 시작이 망설여지셨나요?
                                </div>
                                <div class="cont_title">
                                    애프터닥은 축적된 데이터를 기반으로<br>
                                    병원 상황에 맞는 상담매뉴얼, 환자관리<br>
                                    멘트까지도 직접 제작해드립니다.
                                </div>
                            </div>
                    </div>
                    <div class="flex_column flex_aic gap_40">
                        <div class="third_title">
                            고민만 하고 미뤄뒀던 환자관리 시스템 구축, <br class="mo_br"><span class="main_blue_ft">단 하루만에 해결하세요!</span>
                        </div>
                        <div class="third_btn">
                            <a href="https://www.afterdoc.co.kr/contact/home">10분 방문상담 신청 →</a>
                        </div> 
                    </div>
                </div>
            </div>
        </section>
        
        <div class="pdx_20">
            <div class="page_divider section_title flex_aic_jcc main_blue_ft">
                02.상담 효율화
            </div>
        </div>

        <section class="section_6">
            <div class="section_wrap">
                <div class="section_title">
                    어떤 상담도 놓치지 않고 <br class="mo_br">관리도 편하게, 상담채널 통합
                </div>
                <div class="section_sub_title mgt_16">
                    카카오톡, 인스타그램 등 여러 채널로 들어오는 <br class="mo_br">상담을 한 화면에서 통합관리합니다.<br>
                    <span class="main_blue_ft">재진관리와 신환문의 모두 신속하고<br class="mo_br"> 정확한 응대가 가능해집니다.</span>
                </div>
                <div class="flex_column">
                    <div class="img_cont flex_jcc mgt_50">
                        <img src="@/assets/img/saas_re/section6.png" class="img_pc" alt="카카오톡 인스타그램 등 여러 채널로 들어오는 상담을 한 화면에서 통합관리하고, 재진관리와 신환문의 모두 신속하고 정확한 응대를 하는 이미지">
                        <img src="@/assets/img/saas_re/section6_mo.png" class="img_mo" alt="카카오톡 인스타그램 등 여러 채널로 들어오는 상담을 한 화면에서 통합관리하고, 재진관리와 신환문의 모두 신속하고 정확한 응대를 하는 이미지">
                    </div>
                    <div class="flex_jcc mgt_10">※ 카카오톡 외 연동은 연내 순차적으로 업데이트 됩니다. </div>
                </div>
            </div>
        </section>

        <section class="section_7">
            <div class="section_wrap">
                <div class="section_title">
                    외국인 환자도 한 채널로, <br class="mo_br">
                    <span class="main_blue_ft">통역사 없이 직접상담을</span>
                </div>
                <div class="section_sub_title mgt_16">
                    치료명부터 상담용어까지, 지속적으로 발전하는<br class="mo_br"> 의료상담 전문 AI 번역 기능을 제공합니다.<br>
                    애프터닥을 통해 비용부담 없이<br class="mo_br"> 새로운 기회를 창출해보세요.
                </div>
                <div class="img_cont flex_column flex_aic gap_20 mgt_50">
                    <div class="flag">
                        <img src="@/assets/img/saas_re/flag.png" class="" alt="미국, 중국, 일본 태국 등 국기 이미지">
                    </div>
                    <img src="@/assets/img/saas_re/section7.png" class="img_pc" alt="카카오톡 인스타그램 등 여러 채널로 들어오는 상담을 한 화면에서 통합관리하고, 재진관리와 신환문의 모두 신속하고 정확한 응대를 하는 이미지">
                    <img src="@/assets/img/saas_re/section7_mo.png" class="img_mo" alt="카카오톡 인스타그램 등 여러 채널로 들어오는 상담을 한 화면에서 통합관리하고, 재진관리와 신환문의 모두 신속하고 정확한 응대를 하는 이미지">
                </div>
            </div>
        </section>

        <div class="pdx_20">
            <div class="page_divider section_title flex_aic_jcc main_blue_ft">
                03.환자자산 100% 활용
            </div>
        </div>

        <section class="section_8">
            <div class="section_wrap">
                <div class="section_title">
                    활용할 수 있어야 자산입니다                    
                </div>
                <div class="section_sub_title mgt_16">
                    메세지 발송 과정에서 자연스럽게 환자 DB가 최신화됩니다.<br class="pc_br"> 환자의 치료니즈를 주기적으로 업데이트하여 언제든 활용가능한 자산으로 만듭니다.
                </div>
                <div class="video_wrap mgt_50">
                    <video src="@/assets/img/saas_re/video3.mp4" autoplay muted loop playsinline></video>
                </div>
            </div>
        </section>

        <section class="section_9">
            <div class="section_wrap">
                <div class="section_title">
                    마케팅의 핵심은 <br class="mo_br">고객니즈 세분화와 타겟팅                    
                </div>
                <div class="section_sub_title mgt_16">
                    치료니즈에 따라 자동으로 세분화된 DB로, <br class="mo_br"><span class="main_blue_ft">클릭 한 번에 타겟 마케팅이 가능해집니다.</span><br>
                    비용만 나가고 스팸이 되는 단체문자 대신, <br class="mo_br"> 유효타겟 공략으로 전환율을 높여보세요.
                </div>
                <div class="video_cont flex_aic_spb gap_20">
                    <div class="video_wrap">
                        <video src="@/assets/img/saas_re/video4.mp4" autoplay muted loop playsinline></video>
                    </div>
                    <div><b style="font-size: 22px;">VS</b></div>
                    <div class="img_cont">
                        <img src="@/assets/img/saas_re/section9.png" class="" alt=" 비용만 나가는 스팸성 단체문자 수신받은 이미지">
                    </div>
                </div>
            </div>
        </section>

        <div class="consultation_wrap main_blue_bg">
            <div class="section_title">
                다시 찾고싶은 병원 만들기,<br>
                10분 방문상담을 통해 <br class="mo_br">지금 바로 시작해보세요!     
            </div>
            <div class="flex_aic_jcc mgt_50">
                <a href="https://www.afterdoc.co.kr/contact/home" class="contact_btn main_blue_ft">
                    10분 방문상담 신청 →
                </a>
            </div>
        </div>

        <div class="footer">
            <div class="section_wrap flex_column">
                <div class="flex_aic gap_20">
                    <img src="@/assets/img/saas/logo/medipal.png" alt="">
                    <p>㈜메디팔</p>
                </div>
                <div class="office_info flex_column gap_10 mgt_20">
                    <div class="flex gap_10 row1">
                        <p>전화: 070-5180-4070</p>
                        <p>이메일: contact@medipal.co.kr</p>
                        <p>대표: 강종일</p>
                    </div>
                    <div class="flex row2">
                        <p>주소: 서울시 강남구 테헤란로 420, 16층 (KT 선릉타워 West)</p>
                    </div>
                    <div class="flex row2">
                        <p>사업자 등록번호: 494-88-01653</p>
                    </div>
                </div>
                <div class="copyright">Copyright 2025. Medipal Inc. All Rights Reserved.</div>
            </div>
        </div>

    </div>

</template>


<style>
    @import url(@/assets/css/SaaSMain.css);
</style>

<script>

export default {
    
}
</script>